import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'
import he from 'he'

import RenderIcon from '../utils/render-icon'

class RelatedServices extends Component {

  render() {
    let { title, subtitle, relatedServices } = this.props

    if (!relatedServices?.length) return null

    return (
      <section className="related-services">
        <div className="related-services__inner container">
          <div className="related-services__header">
            <h2>{title}</h2>
            <h3>{subtitle}</h3>
          </div>
          <div className="related-services__items">
            <Fade bottom distance="30px">
              {relatedServices?.map((el, i) => (
                <div className="related-services__item" key={i}>
                  <div className="related-services__icon">
                    {RenderIcon(el.acf.icon)}
                  </div>
                  <div className="related-services__content">
                    <h3>{he.decode(el.acf.shortTitle)}</h3>
                    <Link className="btn btn--border" to={`/service/${el.slug}`}>Learn More</Link>
                  </div>
                  <Link className="relatied-services__link" to={`/service/${el.slug}`}></Link>
                </div>
              ))}
            </Fade>
          </div>
        </div>
      </section>
    )
  }
}

export default RelatedServices
