import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { graphql } from 'gatsby'
import he from 'he'
import Seo from '../components/seo'

import RelatedServices from "../components/related-services"
import Testimonials from "../components/testimonials"
import DownloadGuide from "../components/download-guide"
import Cta from "../components/cta"

function collapseSection(element) {
  let sectionHeight = element.scrollHeight;

  let elementTransition = element.style.transition;
  element.style.transition = '';

  requestAnimationFrame(function () {
    element.style.height = sectionHeight + 'px';
    element.style.transition = elementTransition;

    requestAnimationFrame(function () {
      element.style.height = 0 + 'px';
    });
  });

  element.setAttribute('data-collapsed', 'true');
}

function expandSection(element) {
  let sectionHeight = element.scrollHeight + 16;
  element.style.height = sectionHeight + 'px';
  element.setAttribute('data-collapsed', 'false');
}

class ServiceTemplate extends Component {

  state = {
    itemActive: {}
  }

  componentDidMount() {
    this.scroll = require('smooth-scroll')
    this.scrollToHash()
  }

  componentDidUpdate(prevProps) {
    this.props.location.hash !== prevProps.location.hash && this.scrollToHash()
  }

  scrollToHash() {
    let { hash } = this.props.location
    if (!hash) return
    let target = decodeURIComponent(hash.split('#')[1])
    let element = document.querySelector('.' + target.replace(/[^a-zA-Z0-9]/g, '-').replace(/ /g, '-'))
    if (!element) return
    expandSection(element)
    let itemActive = {}
    itemActive[element.dataset.id] = true
    this.setState({ itemActive })
    new this.scroll().animateScroll(element, 1, { offset: 160, updateURL: false })
  }

  _toggleItem(itemId) {
    let current = this.state.itemActive[itemId]
    let { itemActive } = this.state
    itemActive[itemId] = !current
    let element = document.querySelector(`.tb_${itemId} > div`)
    current ? collapseSection(element) : expandSection(element)
    this.setState({ itemActive })
  }

  render() {
    let service = this.props.data.wpService
    return (
      <>
        <Seo title={he.decode(service.seo.title)} bodyClass={service.slug} description={service.seo.metaDesc} />
        <section className="service">
          <div className="service__inner container">
            <Fade bottom distance="30px">
              <h1 className="service__title">{he.decode(service.title)}</h1>
            </Fade>
            <Fade bottom distance="30px">
              <div className="service__content" dangerouslySetInnerHTML={{ __html: service.content }} />
            </Fade>
            {service.acf.image && (
              <Fade bottom distance="30px">
                <img className="service__image" src={service.acf.image.localFile.childImageSharp.fixed.src} alt={service.title} />
              </Fade>
            )}
            <ul className='text-blocks' id="services">
              {service.acf.services.map((el, i) => (
                <Fade bottom distance='40px' key={i}>
                  <li className={`tb_${i} ${this.state.itemActive[i] ? 'active' : ''}`}>
                    <h4 onClick={e => this._toggleItem(i)}>
                      {el.title}
                      <svg width="10px" height="13px" viewBox="0 0 9 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <g stroke="#231F20" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                          <path d="M-2,6.5 L9.93731888,6.5" transform="translate(4.000000, 6.500000) rotate(-270.000000) translate(-4.000000, -6.500000) "></path>
                          <polyline transform="translate(4.000000, 11.000000) rotate(-360.000000) translate(-4.000000, -11.000000) " points="8 9 4 13 4.4408921e-16 9"></polyline>
                        </g>
                      </svg>
                    </h4>
                    <div data-id={i} className={el.title?.replace(/[^a-zA-Z0-9]/g, '-').replace(/ /g, '-')}>
                      <h5>{el.subtitle}</h5>
                      <div dangerouslySetInnerHTML={{ __html: el.content }} />
                    </div>
                  </li>
                </Fade>
              ))}
            </ul>
          </div>
        </section>
        <RelatedServices title={service.acf.relatedServicesTitle} subtitle={service.acf.relatedServicesSubtitle} relatedServices={service.acf.relatedServices} />
        <Testimonials testimonials={service.acf.testimonials} />
        <DownloadGuide />
        <Cta title={service.acf.ctaTitle} buttonText={service.acf.ctaButtonText} buttonLink={service.acf.ctaButtonLink} />
      </>
    )
  }
}

export default ServiceTemplate

export const serviceQuery = graphql`
  query ($id: String!) {
    wpService(id: {eq: $id}) {
      title
      content
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
            }
          }
        }
      }
      acf {
        icon
        image {
          localFile {
            childImageSharp {
              fixed(width: 1600) {
                src
              }
            }
          }
        }
        testimonials {
          ... on WpTestimonial {
            acf {
              image {
                localFile {
                  childImageSharp {
                    fixed(width: 900) {
                      src
                    }
                  }
                }
              }
              quote
              name
              website
              websiteLink
            }
          }
        }
        relatedServicesTitle
        relatedServicesSubtitle
        relatedServices {
          ... on WpService {
            title
            slug
            acf {
              icon
              shortTitle
            }
          }
        }
        ctaTitle
        ctaButtonText
        ctaButtonLink
        services {
          title
          subtitle
          content
        }
      }
    }
  }
`
